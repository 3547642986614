import { DocumentFormControlType } from 'types/documents.types'
import { ChipColorType } from 'types/materialUI.types'

import routes from './routes'

const DRAFT_STATUS_ID = '1'
const ACTIVE_STATUS_ID = '2'
const INACTIVE_STATUS_ID = '3'

const ALL_STATUS_ID = [
  DRAFT_STATUS_ID,
  ACTIVE_STATUS_ID,
  INACTIVE_STATUS_ID,
] as const

const PRIVACY_POLICY_CATEGORY_ID = 'privacy_notice'
const COOKIE_POLICY_CATEGORY_ID = 'cookie_policy'
const TERMS_OF_USE_CATEGORY_ID = 'terms_of_use'
const OTHER_CATEGORY_ID = 'other'

const ALL_CATEGORIES_ID = [
  PRIVACY_POLICY_CATEGORY_ID,
  COOKIE_POLICY_CATEGORY_ID,
  TERMS_OF_USE_CATEGORY_ID,
  OTHER_CATEGORY_ID,
] as const

const CATEGORY_OPTIONS = [
  { id: PRIVACY_POLICY_CATEGORY_ID, name: 'Aviso de privacidade' },
  { id: COOKIE_POLICY_CATEGORY_ID, name: 'Política de cookies' },
  { id: TERMS_OF_USE_CATEGORY_ID, name: 'Termos de uso' },
  { id: OTHER_CATEGORY_ID, name: 'Outras' },
]

const STATUS_OPTIONS = [
  { id: ACTIVE_STATUS_ID, name: 'Ativo' },
  { id: DRAFT_STATUS_ID, name: 'Rascunho' },
  { id: INACTIVE_STATUS_ID, name: 'Inativo' },
]

const SERVICE_CHANNEL_OPTIONS = [
  { id: true, name: 'Publicados' },
  { id: false, name: 'Não publicados' },
]

const BASE_PAGE_HEADER = [{ name: 'Políticas', url: routes.documents.all }]

const PAGE_HEADER_TYPES = {
  new: [{ name: 'Nova', url: routes.documents.new }],
  edit: [{ name: 'Editar' }],
  view: [{ name: 'Visualizar' }],
}

const TRANSLATION_CATEGORY = {
  [PRIVACY_POLICY_CATEGORY_ID]: 'Aviso de privacidade',
  [COOKIE_POLICY_CATEGORY_ID]: 'Política de cookies',
  [TERMS_OF_USE_CATEGORY_ID]: 'Termos de uso',
  [OTHER_CATEGORY_ID]: 'Outras',
}

const TRANSLATION_STATUS = {
  [ACTIVE_STATUS_ID]: 'ativo',
  [INACTIVE_STATUS_ID]: 'inativo',
  [DRAFT_STATUS_ID]: 'rascunho',
}

const CHIP_STATUS_COLOR: { [key: string]: ChipColorType } = {
  [ACTIVE_STATUS_ID]: 'success',
  [INACTIVE_STATUS_ID]: 'inactive',
  [DRAFT_STATUS_ID]: 'info',
}

const DOCUMENT_INFO_CONTROL_ITEMS: {
  name: DocumentFormControlType
  label: string
}[] = [
  {
    name: 'otherCategory',
    label: 'Especifique a categoria',
  },
  {
    name: 'title',
    label: 'Título',
  },
]

const PERMISSIONS_CONTROL_ITEMS: {
  name: DocumentFormControlType
  label: string
}[] = [
  {
    name: 'publicDocument',
    label:
      'Publicar este documento no seu canal de atendimento ao titular de dados',
  },
  {
    name: 'allowAutomaticEditing',
    label: 'Permitir edição automática do sistema',
  },
]

export default {
  ACTIVE_STATUS_ID,
  BASE_PAGE_HEADER,
  CATEGORY_OPTIONS,
  CHIP_STATUS_COLOR,
  DOCUMENT_INFO_CONTROL_ITEMS,
  DRAFT_STATUS_ID,
  INACTIVE_STATUS_ID,
  OTHER_CATEGORY_ID,
  PAGE_HEADER_TYPES,
  PERMISSIONS_CONTROL_ITEMS,
  SERVICE_CHANNEL_OPTIONS,
  STATUS_OPTIONS,
  TRANSLATION_CATEGORY,
  TRANSLATION_STATUS,
}

export type DocumentStatusType = (typeof ALL_STATUS_ID)[number]
export type DocumentCategoryType = (typeof ALL_CATEGORIES_ID)[number]
