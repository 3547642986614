import { Fragment } from 'react'
import { Box, BoxProps, Divider, Paper, Typography } from '@mui/material'

import { MUI } from 'components'

import { NoDataType } from './NoData.types'

import { theme } from 'theme'
import ErrorImage from 'images/error.svg'

const NoData = ({
  title,
  subTitle,
  withoutPaper = false,
  withTab = false,
  ...rest
}: NoDataType & BoxProps) => {
  const commonContent = (
    <Fragment>
      {withTab && <Divider />}
      <MUI.Box
        display="flex"
        flexDirection="row"
        alignItems="center"
        p={10}
        gap={15}
        $smDown={{ flexDirection: 'column', gap: 8 }}
      >
        <img src={ErrorImage} />
        <MUI.Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          textAlign="start"
          color={theme.palette.inactive.main}
          $smDown={{ textAlign: 'center' }}
        >
          <Typography variant="h6" fontWeight={900}>
            {title}
          </Typography>
          <Typography>{subTitle}</Typography>
        </MUI.Box>
      </MUI.Box>
    </Fragment>
  )

  return withoutPaper ? (
    commonContent
  ) : (
    <Paper component={Box} {...rest} variant="outlined">
      {commonContent}
    </Paper>
  )
}

export default NoData
