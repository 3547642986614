import { DescriptionType } from 'types/banner.types'
import { keyBy, defaults } from 'lodash'

import constants from 'constants/index'

const mergeDescriptions = (bannerDescriptions: DescriptionType[]) => {
  const defaultDescriptionMap = keyBy(
    constants.banner.DEFAULT_DESCRIPTIONS,
    (desc) => desc.language.code
  )

  const bannerDescriptionMap = keyBy(
    bannerDescriptions,
    (desc) => desc.language.code
  )

  return Object.values(
    defaults({}, bannerDescriptionMap, defaultDescriptionMap)
  )
}

export default mergeDescriptions
