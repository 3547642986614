import constants from 'constants/index'

const getHeaderCategories = (pageType: 'view' | 'new' | 'edit') => {
  const { BASE_PAGE_HEADER, PAGE_HEADER_TYPES } = constants.documents

  return [...BASE_PAGE_HEADER, ...(PAGE_HEADER_TYPES[pageType] || [])]
}

export default {
  getHeaderCategories,
}
