import { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Hidden,
  Radio,
  RadioGroup,
  Checkbox,
  Typography,
} from '@mui/material'
import { Launch as LauchIcon } from '@mui/icons-material'

import { Link, MUI } from 'components'

import { theme } from 'theme'

import { BannerConfigurationSchemaType } from 'types/banner.types'

import constants from 'constants/index'

const PositionCustomization = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<BannerConfigurationSchemaType>()

  return (
    <Fragment>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        width="100%"
      >
        <Controller
          render={({ field }) => (
            <FormControl error={!!errors?.position}>
              <MUI.FormLabel $fontWeight={600}>
                Defina o posicionamento do banner de cookies no seu site:
              </MUI.FormLabel>
              <RadioGroup {...field}>
                {constants.banner.CUSTOMIZATION_POSITION_RADIO_GROUP_ITEMS.map(
                  (item, index) => (
                    <Box key={index}>
                      <FormControlLabel
                        control={<Radio />}
                        value={item.value}
                        label={item.label}
                      />
                    </Box>
                  )
                )}
              </RadioGroup>
              {!!errors?.position && (
                <FormHelperText error={!!errors?.position}>
                  {<>{errors?.position?.message}</>}
                </FormHelperText>
              )}
            </FormControl>
          )}
          control={control}
          name="position"
        />
        <Box mt={2}>
          <Controller
            name="hideOnAccept"
            control={control}
            render={({ field }) => (
              <FormControl>
                <MUI.FormLabel $fontWeight={600}>
                  Defina o comportamento de seu banner
                </MUI.FormLabel>
                <FormControlLabel
                  control={
                    <Checkbox {...field} checked={field.value || false} />
                  }
                  label="Esconder banner ao aceitar"
                />
              </FormControl>
            )}
          />
        </Box>
        <Box p={4} bgcolor={theme.palette.custom.light} borderRadius={1}>
          <Typography color="primary" fontWeight={600} gutterBottom>
            Informação
          </Typography>
          <Typography color="primary" textAlign="justify" gutterBottom>
            Ao selecionar esta opção, o banner de cookies será reduzido para uma
            mensagem discreta assim que o usuário aceitar ou recusar os cookies.{' '}
            <strong>Atenção:</strong> será necessário adicionar um elemento HTML
            a mais em seu site.
          </Typography>
          <Typography>
            <Link
              to={constants.banner.LINK_DOCUMENTATION_HIDE_ON_ACCEPT}
              target="_blank"
            >
              Consulte a documentação
              <LauchIcon fontSize="small" sx={{ marginLeft: 2 }} />
            </Link>
          </Typography>
        </Box>
      </Box>
      <Hidden xlDown>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Hidden>
    </Fragment>
  )
}

export default PositionCustomization
