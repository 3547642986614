import { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { isEmpty } from 'lodash'
import { sanitize } from 'dompurify'
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  FormHelperText,
  Switch,
  Typography,
} from '@mui/material'

import { BasicDialog, BasicSection } from 'components'
import { InformationSection } from './components'

import { formatters, validates } from 'helpers'

import { useSnackbar } from 'hooks'

import {
  CurrentDocumentType,
  DocumentFormSchemaType,
} from 'types/documents.types'
import { DocumentFormType } from './documentForm.types'
import { DocumentStatusType } from 'constants/documents'

import schema from './schema'
import service from 'service'
import constants from 'constants/index'

const DocumentForm = ({
  currentDocument,
  setIsLoading = () => {},
  type = 'create',
  refresh = () => {},
}: DocumentFormType) => {
  const { ACTIVE_STATUS_ID, DRAFT_STATUS_ID, PERMISSIONS_CONTROL_ITEMS } =
    constants.documents

  const { getChangedValues } = validates.form

  const [openDialog, setOpenDialog] = useState(false)
  const [submitData, setSubmitData] = useState<Partial<CurrentDocumentType>>({})

  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const defaultValues = {
    title: currentDocument?.title || '',
    category: currentDocument?.category || '',
    otherCategory: currentDocument?.otherCategory || '',
    content: sanitize(currentDocument?.content || ''),
    publicDocument: currentDocument?.publicDocument || false,
    allowAutomaticEditing: currentDocument?.allowAutomaticEditing || false,
  }

  const formMethods = useForm<DocumentFormSchemaType>({
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  })

  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    reset,
    trigger,
  } = formMethods

  const isDraft = currentDocument?.status === DRAFT_STATUS_ID
  const createdBySystem = currentDocument?.createdBySystem

  const isEdit = type === 'edit'
  const isShow = type === 'show'

  const handleOpenDialog = () => setOpenDialog(true)
  const handleCloseDialog = () => setOpenDialog(false)

  const handleBackRedirection = () => navigate(constants.routes.documents.all)

  const handleSubmitValidation = async (status: DocumentStatusType) => {
    const isValid = await trigger()
    const formValues = getValues()

    const changedRelevantValues = getChangedValues(
      {
        title: formValues.title,
        category: formValues.category,
        otherCategory: formValues.otherCategory,
        content: formValues.content,
      },
      {
        title: defaultValues.title,
        category: defaultValues.category,
        otherCategory: defaultValues.otherCategory,
        content: defaultValues.content,
      }
    )

    const changedAnyValues = getChangedValues(formValues, defaultValues)
    const changeStatus = status !== currentDocument?.status

    if (!isValid || (isEmpty(changedAnyValues) && !changeStatus)) return

    if (isEdit && !isEmpty(changedRelevantValues)) {
      setSubmitData({ status })
      handleOpenDialog()
    } else {
      handleSubmit((data) => onSubmit(data, status))()
    }
  }

  const onSubmit = async (
    data: DocumentFormSchemaType,
    status?: DocumentStatusType
  ) => {
    try {
      setIsLoading(true)

      const changedValues = validates.form.getChangedValues(data, defaultValues)

      if (isEdit) {
        await service.dponet.privacyPolicies.update({
          documentId: String(currentDocument?.id) || '',
          status,
          ...changedValues,
        })
      } else {
        await service.dponet.privacyPolicies.create({
          status,
          ...data,
        })
      }

      snackbar.open({
        message: `Documento ${isEdit ? 'editado' : 'criado'} com sucesso!`,
        variant: 'success',
      })

      if (!isEdit) handleBackRedirection()

      reset({})
      refresh()
      handleCloseDialog()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormProvider {...formMethods}>
      <Box display="flex" flexDirection="column" gap={8}>
        {isDraft && (
          <Alert variant="filled" severity="info">
            Este conteúdo é um rascunho e suas ações no canal de atendimento não
            terão efeito.
          </Alert>
        )}
        <BasicSection title="Permissões">
          <Box display="flex" flexDirection="column" gap={2}>
            {PERMISSIONS_CONTROL_ITEMS.map((item, index) => {
              if (!createdBySystem && item?.name === 'allowAutomaticEditing')
                return null

              return (
                <Fragment key={index}>
                  <Controller
                    control={control}
                    name={item?.name}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Switch
                            {...field}
                            checked={Boolean(field?.value)}
                            onChange={(event) =>
                              field.onChange(event.target.checked)
                            }
                          />
                        }
                        label={item?.label}
                        color="primary"
                        disabled={isShow}
                      />
                    )}
                  />
                  {!!errors?.[item?.name] && (
                    <FormHelperText error={!!errors?.[item?.name]}>
                      {<>{errors?.[item?.name]?.message}</>}
                    </FormHelperText>
                  )}
                </Fragment>
              )
            })}
          </Box>
        </BasicSection>
        <InformationSection currentDocument={currentDocument} type={type} />
        {!isShow && (
          <Box display="flex" justifyContent="flex-end" gap={2}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                handleSubmitValidation(DRAFT_STATUS_ID as DocumentStatusType)
              }
            >
              Salvar rascunho
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                handleSubmitValidation(ACTIVE_STATUS_ID as DocumentStatusType)
              }
            >
              Publicar
            </Button>
          </Box>
        )}
        <BasicDialog
          open={openDialog}
          onClose={handleCloseDialog}
          title="Salvar documento"
          dialogActions={
            <Button
              variant="contained"
              onClick={handleSubmit((data) =>
                onSubmit(data, submitData?.status)
              )}
            >
              Salvar
            </Button>
          }
        >
          <Typography>
            Tem certeza de que deseja alterar este documento?
            <br />
            <strong>Isso irá gerar uma nova versão!</strong>
          </Typography>
        </BasicDialog>
      </Box>
    </FormProvider>
  )
}

export default DocumentForm
