import * as yup from 'yup'

import { validates } from 'helpers'

const schema = yup.object({
  publicDocument: yup.boolean().required(),
  category: yup.string().required(),
  otherCategory: yup
    .string()
    .max(255)
    .when('category', {
      is: 'other',
      then: (schemaOption) => schemaOption.required(),
    }),
  title: yup.string().max(255).required(),
  content: yup
    .string()
    .required()
    .test('hasContent', 'Preencha esse campo', validates.jodit.hasContent),
  allowAutomaticEditing: yup.boolean().required(),
})

export default schema
