import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { isEmpty } from 'lodash'
import {
  Box,
  Button,
  Grid,
  Hidden,
  TablePagination,
  Typography,
} from '@mui/material'

import {
  BasicDialog,
  FilterButton,
  LoadingFeedback,
  NoData,
  PageHead,
  StandardPageStructure,
} from 'components'
import { DocumentFilter, DocumentPreviewCard } from './components'

import { formatters } from 'helpers'

import { useFetch, useFilter, usePagination, useSnackbar } from 'hooks'

import { CurrentDocumentType } from 'types/documents.types'
import { DocumentStatusType } from 'constants/documents'

import service from 'service'
import constants from 'constants/index'

const DocumentsMain = () => {
  const { ACTIVE_STATUS_ID, INACTIVE_STATUS_ID } = constants.documents

  const [openInactivationModal, setOpenInactivationModal] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [documentData, setDocumentData] = useState<
    Partial<CurrentDocumentType>
  >({})
  const [isLoading, setIsLoading] = useState(false)

  const filter = useFilter()
  const navigate = useNavigate()
  const { snackbar } = useSnackbar()

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination({ initialPerPage: 5 })

  const { response, loading, refresh } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      page,
      perPage,
      ...filter.filters,
    },
    [page, perPage, filter.filters]
  )

  const privacyPolicies: CurrentDocumentType[] =
    response?.data?.privacyPolicies || []

  const isInactive = documentData?.status === INACTIVE_STATUS_ID

  const handleCreationRedirect = () => navigate(constants.routes.documents.new)

  const handleOpenInactivationModal = (
    id: number,
    status?: DocumentStatusType
  ) => {
    setDocumentData({ id, status })
    setOpenInactivationModal(true)
  }
  const handleOpenDeleteModal = (id: number) => {
    setDocumentData({ id })
    setOpenDeleteModal(true)
  }

  const handleCloseInactivationModal = () => setOpenInactivationModal(false)
  const handleCloseDeleteModal = () => setOpenDeleteModal(false)

  const inactivateDocument = async () => {
    try {
      setIsLoading(true)

      const status = isInactive ? ACTIVE_STATUS_ID : INACTIVE_STATUS_ID

      await service.dponet.privacyPolicies.update({
        documentId: String(documentData?.id),
        privacyPolicy: { status },
      })

      snackbar.open({
        message: `Documento ${isInactive ? 'inativado' : 'ativado'} com sucesso!`,
        variant: 'success',
      })
      refresh()
      handleCloseInactivationModal()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const deleteDocument = async () => {
    try {
      setIsLoading(true)

      await service.dponet.privacyPolicies.destroy(documentData?.id)

      snackbar.open({
        message: `Documento excluído com sucesso!`,
        variant: 'success',
      })
      refresh()
      handleCloseDeleteModal()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: formatters.errorMessage(error),
        variant: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading} />
      <PageHead
        name="Documentos"
        actionButton={
          <Box display="flex" gap={4}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreationRedirect}
            >
              Novo documento
            </Button>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        }
      />
      {isEmpty(privacyPolicies) && !loading ? (
        <NoData title="Nenhum documento foi encontrado." />
      ) : (
        <Grid container spacing={4}>
          {loading
            ? Array.from({ length: 6 }).map((_, index) => (
                <Grid key={index} item xs={12} md={6} lg={4}>
                  <DocumentPreviewCard skeleton />
                </Grid>
              ))
            : privacyPolicies?.map((privacyPolicy) => (
                <Grid key={privacyPolicy?.id} item xs={12} md={4}>
                  <DocumentPreviewCard
                    currentDocument={privacyPolicy}
                    handleOpenInactivationModal={handleOpenInactivationModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                  />
                </Grid>
              ))}
        </Grid>
      )}
      {!isEmpty(privacyPolicies) && (
        <TablePagination
          component="div"
          count={response?.data?.meta?.totalCount || 0}
          onPageChange={(_, page) => handleChangePage(page)}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          rowsPerPage={perPage}
          rowsPerPageOptions={[5, 10, 25, 100]}
          labelRowsPerPage={<Hidden smDown>Por página</Hidden>}
          slotProps={{
            actions: {
              previousButton: { size: 'small' },
              nextButton: { size: 'small' },
            },
          }}
        />
      )}
      <BasicDialog
        open={openDeleteModal}
        onClose={handleCloseDeleteModal}
        title="Excluir documento"
        dialogActions={
          <Button variant="contained" color="error" onClick={deleteDocument}>
            Excluir
          </Button>
        }
      >
        <Typography>
          Tem certeza de que deseja excluir este documento?
        </Typography>
        <Typography color="error" fontWeight={500}>
          Esta ação não poderá ser desfeita!
        </Typography>
      </BasicDialog>
      <BasicDialog
        open={openInactivationModal}
        onClose={handleCloseInactivationModal}
        title={`${isInactive ? 'Ativar' : 'Inativar'} documento`}
        dialogActions={
          <Button
            variant="contained"
            color="primary"
            onClick={inactivateDocument}
          >
            {isInactive ? 'Ativar' : 'Inativar'}
          </Button>
        }
      >
        <Typography>
          Tem certeza de que deseja {isInactive ? 'ativar' : 'inativar'} este
          documento?
        </Typography>
      </BasicDialog>
      <DocumentFilter filter={filter} />
    </StandardPageStructure>
  )
}

export default DocumentsMain
