import { Chip as ChipMui } from '@mui/material'
import { styled } from '@mui/material/styles'

import { Property } from 'csstype'
import { TLength } from 'types/materialUI.types'

const Chip = styled(ChipMui, {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
})<{
  $background?: Property.Background<TLength>
  $backgroundColor?: Property.BackgroundColor
  $borderRadius?: Property.BorderRadius<TLength>
  $color?: Property.Color
  $fontWeight?: Property.FontWeight
  $height?: Property.Height<TLength>
  $textTransform?: Property.TextTransform
  $width?: Property.Width<TLength>
}>(
  ({
    $background,
    $backgroundColor,
    $borderRadius = 3,
    $color,
    $fontWeight = 500,
    $height,
    $textTransform,
    $width,
  }) => ({
    background: $background,
    backgroundColor: $backgroundColor,
    borderRadius: $borderRadius,
    color: $color,
    fontWeight: $fontWeight,
    height: $height,
    textTransform: $textTransform,
    width: $width,
  })
)

export default Chip
