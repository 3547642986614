import { Box, IconButton, Typography } from '@mui/material'
import { LanguageCustomizationType } from './languageCustomization.types'
import constants from 'constants/index'

const LanguageCustomization = ({
  selectedLanguage,
  setSelectedLanguage,
}: LanguageCustomizationType) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      mb={2}
      gap={2}
      paddingX={1}
      alignItems="flex-start"
    >
      <Typography fontSize={16} fontWeight="bold" color="primary">
        Idioma
      </Typography>
      <Box display="flex" justifyContent="center" flexWrap="wrap" gap={2}>
        {constants.language.LANGUAGES.map((language) => (
          <IconButton
            key={language.code}
            onClick={() => setSelectedLanguage(language.code)}
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              transition: 'transform 0.3s ease, filter 0.3s ease',
              '& img': {
                filter: 'grayscale(100%)',
                transition: 'filter 0.3s ease',
              },
              '&:hover': {
                transform: 'scale(1.1)',
              },
              ...(selectedLanguage === language.code && {
                '& img': {
                  filter: 'grayscale(0%)',
                },
              }),
            }}
          >
            <img
              src={language.flag}
              alt={`${language.name} flag`}
              width="40px"
              style={{ aspectRatio: 16 / 9 }}
            />
          </IconButton>
        ))}
      </Box>
    </Box>
  )
}

export default LanguageCustomization
