import * as yup from 'yup'

import { formatters, validates } from 'helpers'

import constants from 'constants/index'

const allowedTypes = constants.privacyPortal.SUPPORTED_LOGO_FILE_INPUT_TYPES

const schema = yup
  .object({
    hasLogo: yup.bool().required('Escolha uma opção'),
    logo: yup
      .mixed<File>()
      .nullable()
      .when('hasLogo', {
        is: (hasLogo?: string | null) => hasLogo,
        then: () =>
          yup
            .mixed<File>()
            .test('fileSize', 'A imagem deve ter no máximo 5 MB', (value) =>
              validates.file.size('MB', 5, value)
            )
            .test(
              'fileType',
              `Somente arquivos ${formatters.file.allowedTypes(allowedTypes)} são permitidos`,
              (value) =>
                validates.file.type(
                  constants.privacyPortal.SUPPORTED_IMAGE_FILE_SCHEMA_TYPES,
                  value
                )
            )
            .required(),
      }),
  })
  .required()

export type CompanyLogoSchemaType = yup.InferType<typeof schema>

export default schema
