import { Controller, useFormContext } from 'react-hook-form'
import { Box, TextField, Typography } from '@mui/material'

import { BasicSection, CopyInput, Select, TextEditor } from 'components'

import { InformationSectionType } from './informationSection.types'
import { DocumentFormSchemaType } from 'types/documents.types'

import { theme } from 'theme'
import constants from 'constants/index'

const InformationSection = ({
  currentDocument,
  type,
}: InformationSectionType) => {
  const {
    CATEGORY_OPTIONS,
    DOCUMENT_INFO_CONTROL_ITEMS,
    OTHER_CATEGORY_ID,
    ACTIVE_STATUS_ID,
  } = constants.documents

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<DocumentFormSchemaType>()

  const category = watch('category')
  const hasSpecify = category !== OTHER_CATEGORY_ID

  const isShow = type === 'show'

  const isActive = currentDocument?.status === ACTIVE_STATUS_ID

  return (
    <BasicSection title="Informações do documento">
      <Box display="flex" flexDirection="column" gap={5}>
        <Controller
          control={control}
          name="category"
          render={({ field }) => (
            <Select
              {...field}
              label="Categoria do documento"
              error={!!errors?.category}
              helperText={<>{errors?.category?.message}</>}
              items={CATEGORY_OPTIONS}
              disabled={isShow}
              fullWidth
            />
          )}
        />
        {DOCUMENT_INFO_CONTROL_ITEMS?.map((item, index) => {
          if (item?.name === 'otherCategory' && hasSpecify) return null

          return (
            <Controller
              key={index}
              control={control}
              name={item?.name}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={item?.label}
                  fullWidth
                  error={!!errors?.[item?.name]}
                  helperText={<>{errors?.[item?.name]?.message}</>}
                  disabled={isShow}
                />
              )}
            />
          )
        })}
        <Box>
          <Typography
            fontWeight={500}
            color={theme.palette.neutral.main}
            gutterBottom
          >
            Conteúdo do documento
          </Typography>
          <Controller
            control={control}
            name="content"
            render={({ field: { onChange, value, name } }) => (
              <TextEditor
                value={value}
                onChange={onChange}
                error={!!errors?.[name]}
                helperText={errors?.[name]?.message}
                configRest={{
                  height: 450,
                  buttons: constants.jodit.DEFAULT_BUTTONS,
                }}
                disabled={isShow}
              />
            )}
          />
        </Box>
        {!!currentDocument?.generateDocumentLink &&
          currentDocument?.publicDocument &&
          isActive && (
            <CopyInput
              label="Link de incorporação"
              content={currentDocument?.generateDocumentLink}
              InputPropsValue={{
                style: { backgroundColor: theme.palette.custom.light },
              }}
            />
          )}
      </Box>
    </BasicSection>
  )
}

export default InformationSection
