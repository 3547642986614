import Edit from './DocumentsEdit'
import Main from './DocumentsMain'
import New from './DocumentsNew'
import Show from './DocumentsShow'

export default {
  Edit,
  Main,
  New,
  Show,
}
