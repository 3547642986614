import alert from './alert'
import banner from './banner'
import cardFlags from './cardFlags'
import checkout from './checkout'
import consent from './consent'
import cookie from './cookie'
import documents from './documents'
import faqs from './faqs'
import filterOrder from './filterOrder'
import jodit from './jodit'
import jsPdf from './jsPdf'
import language from './language'
import marketplace from './marketplace'
import maturityQuestionnaire from './maturityQuestionnaire'
import mooksValues from './mooksValues'
import paymentOrder from './paymentOrder'
import plan from './plan'
import privacyChannelConfigurationSteps from './privacyChannelConfigurationSteps'
import privacyPortal from './privacyPortal'
import routes from './routes'
import segments from './segments'
import serviceChannel from './serviceChannel'
import settings from './settings'
import ticket from './ticket'
import validations from './validations'
import welcomeSteps from './welcomeSteps'

const constants = {
  alert,
  banner,
  cardFlags,
  checkout,
  consent,
  cookie,
  documents,
  faqs,
  filterOrder,
  jodit,
  jsPdf,
  language,
  marketplace,
  maturityQuestionnaire,
  mooksValues,
  paymentOrder,
  plan,
  privacyChannelConfigurationSteps,
  privacyPortal,
  routes,
  segments,
  serviceChannel,
  settings,
  ticket,
  validations,
  welcomeSteps,
}

export default constants
