import { Copy as CopyIcon } from 'react-feather'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'

import { useClipboard } from 'hooks'

import { CopyInputType } from './copyInput.types'

const CopyInput = ({
  content = '',
  disabledCopy = false,
  InputPropsValue,
  ...rest
}: TextFieldProps & CopyInputType) => {
  const { copy } = useClipboard()

  const handleCopyContent = () => copy(content)

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled
      value={content}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              size="small"
              onClick={handleCopyContent}
              disabled={!content || disabledCopy}
              color="primary"
            >
              <CopyIcon size={18} />
            </IconButton>
          </InputAdornment>
        ),
        ...InputPropsValue,
      }}
      {...rest}
    />
  )
}

export default CopyInput
