import { useState } from 'react'

import { LoadingFeedback, PageHead, StandardPageStructure } from 'components'
import { DocumentForm } from './components'

import { validates } from 'helpers'

import routes from 'constants/routes'

const DocumentsNew = () => {
  const [isLoading, setIsLoading] = useState(false)

  const categories = validates.documents.getHeaderCategories('new')

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading} />
      <PageHead
        name="Novo documento"
        returnedUrl={routes.documents.all}
        categories={categories}
      />
      <DocumentForm setIsLoading={setIsLoading} />
    </StandardPageStructure>
  )
}

export default DocumentsNew
