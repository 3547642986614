import { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Power as PowerIcon, Trash as TrashIcon } from 'react-feather'
import { reverse } from 'named-urls'
import {
  Box,
  Button,
  Card,
  CardContent,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Skeleton,
  Tooltip,
  Typography,
} from '@mui/material'

import { MenuButton, MUI } from 'components'

import { DocumentPreviewCardType } from './documentPreviewCard.types'

import constants from 'constants/index'

const DocumentPreviewCard = ({
  currentDocument,
  handleOpenInactivationModal = () => {},
  handleOpenDeleteModal = () => {},
  skeleton = false,
}: DocumentPreviewCardType) => {
  const {
    TRANSLATION_CATEGORY,
    TRANSLATION_STATUS,
    CHIP_STATUS_COLOR,
    INACTIVE_STATUS_ID,
  } = constants.documents

  const navigate = useNavigate()

  const documentId = currentDocument?.id || ''
  const category = currentDocument?.category
  const status = currentDocument?.status

  const isInactive = currentDocument?.status === INACTIVE_STATUS_ID

  const categoryLabel =
    (category && TRANSLATION_CATEGORY[category]) || 'Indefinido'

  const statusLabel = (status && TRANSLATION_STATUS[status]) || 'Indefinido'

  const statusColor = (status && CHIP_STATUS_COLOR[status]) || 'neutral'

  const MENU_OPTIONS = [
    {
      icon: PowerIcon,
      title: isInactive ? 'Ativar' : 'Inativar',
      handleClick: () =>
        handleOpenInactivationModal(Number(documentId), status),
    },
    {
      icon: TrashIcon,
      title: 'Excluir',
      handleClick: () => handleOpenDeleteModal(Number(documentId)),
    },
  ]

  const handleShowRedirection = () =>
    navigate(reverse(constants.routes.documents.show, { documentId }))
  const handleEditRedirection = () =>
    navigate(reverse(constants.routes.documents.edit, { documentId }))

  return (
    <Card variant="outlined">
      <CardContent
        component={Box}
        display="flex"
        flexDirection="column"
        gap={4}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            flexGrow={1}
            overflow="hidden"
          >
            {skeleton ? (
              <Fragment>
                <Skeleton variant="rounded" height={24} />
                <Skeleton variant="text" />
              </Fragment>
            ) : (
              <Fragment>
                <Box>
                  <Tooltip title={categoryLabel || ''} arrow placement="right">
                    <MUI.Chip
                      label={categoryLabel}
                      color="info"
                      size="small"
                      $textTransform="uppercase"
                    />
                  </Tooltip>
                </Box>
                <Tooltip
                  title={`#${currentDocument?.id} - ${currentDocument?.title}`}
                  placement="top-end"
                >
                  <Typography fontWeight={500} noWrap>
                    #{currentDocument?.id} - {currentDocument?.title}
                  </Typography>
                </Tooltip>
              </Fragment>
            )}
          </Box>
          {skeleton ? (
            <Skeleton variant="circular" width={40} height={40} />
          ) : (
            <MenuButton id={`document-preview-${currentDocument?.id}`}>
              {MENU_OPTIONS?.map((option, index) => (
                <MenuItem key={index} onClick={option.handleClick}>
                  <ListItemIcon>
                    <option.icon size={16} strokeWidth={2.5} />
                  </ListItemIcon>
                  <ListItemText>{option?.title}</ListItemText>
                </MenuItem>
              ))}
            </MenuButton>
          )}
        </Box>
        {skeleton ? (
          <Skeleton variant="rounded" height={32} />
        ) : (
          <MUI.Chip
            label={statusLabel}
            $textTransform="uppercase"
            color={statusColor}
            $fontWeight={600}
          />
        )}
        <Box display="flex" gap={4}>
          {skeleton ? (
            <Fragment>
              <Skeleton variant="rounded" height={36} width="100%" />
              <Skeleton variant="rounded" height={36} width="100%" />
            </Fragment>
          ) : (
            <Fragment>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                onClick={handleShowRedirection}
              >
                Visualizar
              </Button>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleEditRedirection}
              >
                Editar
              </Button>
            </Fragment>
          )}
        </Box>
      </CardContent>
    </Card>
  )
}

export default DocumentPreviewCard
