/* eslint-disable @typescript-eslint/no-explicit-any */

const normalize = (value: unknown) =>
  value === undefined || value === null || value === '' ? '' : value

const getChangedValues = <T extends Record<string, any>>(
  currentValues: T,
  defaultValues: T
) => {
  const changedValues: Record<string, any> = {}

  Object.keys(currentValues).forEach((key) => {
    if (normalize(currentValues[key]) !== normalize(defaultValues[key])) {
      changedValues[key] = currentValues[key]
    }
  })

  return changedValues
}

export default { getChangedValues, normalize }
