import { Box, Divider, Paper, Typography } from '@mui/material'

import { BasicSectionType } from './basicSection.types'

const BasicSection = ({
  title,
  extraActions,
  children,
  isTable = false,
  hasPagination = false,
}: BasicSectionType) => {
  return (
    <Paper
      variant="outlined"
      component={Box}
      {...(!hasPagination && isTable && { borderBottom: '0px !important' })}
    >
      <Box p={4} display="flex" justifyContent="space-between">
        <Typography color="primary" fontWeight={500}>
          {title}
        </Typography>
        {extraActions}
      </Box>
      <Divider />
      <Box p={isTable ? 0 : 4} display="flex" flexDirection="column" gap={4}>
        {children}
      </Box>
    </Paper>
  )
}

export default BasicSection
