import { include } from 'named-urls'

const routes = {
  root: '/',
  maturityQuestionnaire: '/questionnaire',
  notFound: '/not-found',
  forbidden: '/forbidden',
  internalError: '/internal-error',
  offline: '/offline',
  steps: {
    welcome: '/welcome',
  },
  auth: include('/auth', {
    login: '',
    register: 'register',
    recoveryPassword: 'recovery-password',
    resetPassword: 'new-password/:resetPasswordToken',
  }),
  banners: include('/banners', {
    all: '',
    settings: ':bannerId/settings',
  }),
  consents: include('/consents', {
    all: '',
  }),
  documents: include('/documents', {
    all: '',
    new: 'new',
    edit: ':documentId/edit',
    show: ':documentId/show',
  }),
  tickets: include('/tickets', {
    all: '',
    show: ':ticketId/show',
  }),
  serviceChannel: include('/service-channel', {
    automaticAnswer: 'automatic-answer',
    stamps: 'stamps',
  }),
  privacyChannel: include('/privacy-channel', {
    configuration: 'configuration',
  }),
  marketplace: include('/marketplace', {
    all: '',
    service: 'service/:serviceId',
    products: 'products',
    productShow: 'show/:partnerServiceId',
    orders: 'orders',
  }),
  checkout: include('/checkout', {
    all: '',
    upgrade: 'upgrade',
  }),
  settings: include('/settings', {
    all: '',
  }),
  faqs: include('/faqs', {
    all: '',
    show: ':faqId/show',
  }),
}

export default routes
