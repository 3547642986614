import { FilterInput, Filters } from 'components'

import { FilterHookType } from 'types/filters.types'

import constants from 'constants/index'

const DocumentFilter = ({ filter }: { filter: FilterHookType }) => {
  return (
    <Filters filter={filter}>
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Identificador', name: 'id' }}
      />
      <FilterInput
        fieldConfigs={{ textFieldInput: true }}
        {...{ label: 'Título', name: 'title' }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Categoria',
          name: 'category',
          options: constants.documents.CATEGORY_OPTIONS,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Canal de atendimento',
          name: 'publicDocument',
          options: constants.documents.SERVICE_CHANNEL_OPTIONS,
        }}
      />
      <FilterInput
        fieldConfigs={{ fieldAutocomplete: true }}
        {...{
          label: 'Status',
          name: 'status',
          options: constants.documents.STATUS_OPTIONS,
        }}
      />
    </Filters>
  )
}

export default DocumentFilter
