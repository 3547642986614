import { Box } from '@mui/material'
import { useFormContext } from 'react-hook-form'

import BannerPreview from '../BannerPreview'

import { theme } from 'theme'
import constants from 'constants/index'

interface PositionPreviewProps {
  description: string
  selectedLanguage: string
}

const PositionPreview = ({
  description,
  selectedLanguage,
}: PositionPreviewProps) => {
  const { watch } = useFormContext()

  const position = watch('position')

  const isFixed = position === constants.banner.BANNER_FIXED_POSITION

  return (
    <Box
      bgcolor={theme.palette.disabled.background}
      height="100%"
      borderRadius={2}
      p={!isFixed ? 4 : 0}
    >
      <BannerPreview
        position={position}
        iconProps={{ p: isFixed ? 4 : 0 }}
        description={description}
        selectedLanguage={selectedLanguage}
      />
    </Box>
  )
}

export default PositionPreview
