import banner from './banner'
import cardFlag from './cardFlag'
import chart from './chart'
import checkout from './checkout'
import consent from './consent'
import documents from './documents'
import file from './file'
import filterOrder from './filterOrder'
import form from './form'
import jodit from './jodit'
import marketplace from './marketplace'
import serviceChannel from './serviceChannel'
import settings from './settings'
import testValidator from './testValidator'
import ticket from './ticket'
import ticketInfoTable from './ticketInfoTable'
import ticketMessages from './ticketMessages'

export default {
  banner,
  cardFlag,
  chart,
  checkout,
  consent,
  documents,
  file,
  filterOrder,
  form,
  jodit,
  marketplace,
  serviceChannel,
  settings,
  testValidator,
  ticket,
  ticketInfoTable,
  ticketMessages,
}
