import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { LoadingFeedback, PageHead, StandardPageStructure } from 'components'
import { DocumentForm } from './components'

import { validates } from 'helpers'

import { useFetch } from 'hooks'

import { CurrentDocumentType } from 'types/documents.types'

import service from 'service'
import routes from 'constants/routes'

const DocumentsEdit = () => {
  const [isLoading, setIsLoading] = useState(false)

  const { documentId } = useParams()

  const { response, loading, refresh } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      documentId,
    },
    [documentId],
    !!documentId
  )

  const privacyPolicy: CurrentDocumentType = response?.data?.privacyPolicy || []

  const categories = validates.documents.getHeaderCategories('edit')

  return (
    <StandardPageStructure>
      <LoadingFeedback open={isLoading || loading} />
      <PageHead
        name="Editar documento"
        returnedUrl={routes.documents.all}
        categories={categories}
      />
      {!loading && (
        <DocumentForm
          setIsLoading={setIsLoading}
          currentDocument={privacyPolicy}
          refresh={refresh}
          type="edit"
        />
      )}
    </StandardPageStructure>
  )
}

export default DocumentsEdit
