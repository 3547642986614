import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const stamps = async () => await dponetAPI.get('/privacy_portal/stamps')

const consult = async () => await dponetAPI.get('/privacy_portal/consult')

export default {
  stamps,
  consult,
}
