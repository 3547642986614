import React, { useCallback } from 'react'
import { Box, Divider, Hidden, TextField } from '@mui/material'

import { MUI } from 'components'

import { DescriptionCustomizationType } from './descriptionCustomization.types'
import LanguageCustomization from '../LanguageCustomization'

const DescriptionCustomization = React.memo(
  ({
    selectedLanguage,
    setSelectedLanguage,
    setLocalDescription,
    localDescription,
    debouncedDescriptionChange,
  }: DescriptionCustomizationType) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocalDescription(e.target.value)
        debouncedDescriptionChange(selectedLanguage, e.target.value)
      },
      [selectedLanguage, debouncedDescriptionChange]
    )

    return (
      <MUI.Box display="flex" $gap={4} $width="100%">
        <MUI.Box $gap={4} display="flex" flexDirection="column" $width="100%">
          <LanguageCustomization
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
          <Box width="100%">
            <TextField
              label={`Descrição (${selectedLanguage})`}
              type="text"
              rows={9}
              multiline
              fullWidth
              value={localDescription}
              onChange={handleChange}
            />
          </Box>
        </MUI.Box>
        <Hidden lgDown>
          <Divider orientation="vertical" variant="middle" flexItem />
        </Hidden>
      </MUI.Box>
    )
  }
)

export default DescriptionCustomization
