import { forwardRef, Ref } from 'react'

import {
  Box,
  MenuItem,
  TextField,
  TextFieldProps,
  Tooltip,
  Typography,
} from '@mui/material'

import { SelectType } from './select.types'

const Select = forwardRef(
  ({ items, tooltipProps, ...rest }: TextFieldProps & SelectType, ref) => {
    return (
      <TextField
        select
        variant="outlined"
        ref={ref as Ref<HTMLDivElement>}
        {...rest}
      >
        {items?.map((item, index) => (
          <MenuItem key={index} value={item?.id}>
            <Box width="100%">
              <Tooltip
                title={item?.name || ''}
                placement="top"
                {...tooltipProps}
              >
                <Typography>{item?.name}</Typography>
              </Tooltip>
            </Box>
          </MenuItem>
        ))}
      </TextField>
    )
  }
)

export default Select
