import api from 'service/api'

const dponetAPI = api.create('dponet', false)

const get = async ({ documentId = '', ...params }) => {
  return await dponetAPI.get(`/privacy_policies/${documentId}`, { params })
}

const destroy = async (documentId?: string | number) => {
  return await dponetAPI.delete(`/privacy_policies/${documentId}`)
}

const create = async ({ ...data }) => {
  return await dponetAPI.post('/privacy_policies', data)
}

const update = async ({ documentId = '', ...data }) => {
  return await dponetAPI.put(`/privacy_policies/${documentId}`, data)
}

export default {
  create,
  destroy,
  get,
  update,
}
