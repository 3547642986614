import { useParams } from 'react-router-dom'

import { LoadingFeedback, PageHead, StandardPageStructure } from 'components'
import { DocumentForm } from './components'

import { validates } from 'helpers'

import { useFetch } from 'hooks'

import { CurrentDocumentType } from 'types/documents.types'

import routes from 'constants/routes'
import service from 'service'

const DocumentsShow = () => {
  const { documentId } = useParams()

  const { response, loading } = useFetch(
    service.dponet.privacyPolicies.get,
    {
      documentId,
    },
    [documentId],
    !!documentId
  )

  const privacyPolicy: CurrentDocumentType = response?.data?.privacyPolicy || []

  const categories = validates.documents.getHeaderCategories('view')

  return (
    <StandardPageStructure>
      <LoadingFeedback open={loading} />
      <PageHead
        name="Visualizar documento"
        returnedUrl={routes.documents.all}
        categories={categories}
      />
      {!loading && <DocumentForm currentDocument={privacyPolicy} type="show" />}
    </StandardPageStructure>
  )
}

export default DocumentsShow
