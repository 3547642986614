import { forwardRef, useMemo, useRef } from 'react'
import JoditEditor, { Jodit } from 'jodit-react'
import { Box, FormHelperText } from '@mui/material'

import { TextEditorType } from './textEditor.types'

import constants from 'constants/index'
import { theme } from 'theme'

const TextEditor = forwardRef<Jodit | null, TextEditorType>(
  (
    {
      onChange,
      value = '',
      error = false,
      helperText,
      disabled = false,
      configRest,
      ...rest
    },
    ref
  ) => {
    const editor = useRef<Jodit | null>(null)

    const config = useMemo(
      () => ({
        language: 'pt_br',
        spellcheck: true,
        showXPathInStatusbar: false,
        buttons: constants.jodit.EDITOR_OPTIONS,
        askBeforePasteHTML: false,
        style: {
          fontFamily: 'Roboto',
          fontSize: 16,
          ...(!!error && {
            border: `1px solid ${theme.palette.error.main}`,
            color: theme.palette.error.main,
          }),
          ...(disabled && {
            color: theme.palette.inactive.main,
          }),
        },
        height: 300,
        zIndex: 1,
        ...(disabled && { readonly: true }),
        ...configRest,
      }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [error, disabled]
    )

    return (
      <Box>
        <JoditEditor
          config={config}
          ref={ref || editor}
          onBlur={(value) => onChange(value)}
          value={value}
          {...rest}
        />
        {!!error && (
          <FormHelperText error={!!error}>{String(helperText)}</FormHelperText>
        )}
      </Box>
    )
  }
)

export default TextEditor
